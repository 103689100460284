<script setup lang="ts">
/**
 * @author Irakli Kandelaki, Luka Tchigladze
 */
/** IMPORT HOOKS */
import {
  ref,
  computed,
  onBeforeUnmount,
  watch,
} from "vue";

/** IMPORT ROUTER */

/** IMPORT STORE */
import { useBuildingStore } from "@/store/building";
import { useMainStore } from "@/store/main/index";

import { sendEvent } from "@/composables/utils/sendEvent";

/** IMPORT COMPONENTS */
import Tooltip from "./parts/Tooltip.vue";
import LiveCameraButton from "@/components/regularComponents/UIComponents/LiveCameraButton.vue";
// import Gallery from "../Gallery/Gallery.vue";
import Filters from "@/components/regularComponents/BuildingModule/parts/Filters.vue";
import Button from "@/components/regularComponents/UIComponents/Button.vue";
// import Vue3VideoPlayer from "@cloudgeek/vue3-video-player";
// import "@cloudgeek/vue3-video-player/dist/vue3-video-player.css";

// import { getTaxonomySS } from "@/composables/core/func";

/** IMPORT TYPES */
import type { Ref } from "vue";
import type { FloorHover, BlockHover } from "./ts/renderTypes";
import type { SecondaryDataInterface } from "@/types/ContentType";

/** DEFINE STORE */
const buildingStore = useBuildingStore();

const mainStore = useMainStore();

// setTimeout(async () => {
//   await getTaxonomySS("project_status");
// }, 1000);

const selectedComponentsState = useState<SecondaryDataInterface[]>("selectedComponents");

/** REACTIVE DATA */
const hoveredFloor: Ref<FloorHover | undefined> = ref();
const hoveredBlock: Ref<BlockHover | undefined> = ref();

const currentImage: Ref<number> = ref(0);
const activeModal: Ref<boolean> = ref(false);
// const isItVideo: Ref<boolean> = ref(true);
const projectsRenderEl: Ref<HTMLElement | undefined> = ref();
const svgContainer: Ref<HTMLElement | undefined> = ref();
const groupEls: Ref<NodeListOf<SVGGElement> | undefined> = ref();
const showTooltip: Ref<boolean> = ref(false);
const floorNumberMin: Ref<number> = ref(1000);
const floorNumberMax: Ref<number> = ref(-1000);
const defaultFloorSet: Ref<boolean> = ref(false);

const isDesktop = computed(
  () => mainStore.windowWidth > mainStore.breakpoints.tablet
);
const isMobile = computed(
  () => mainStore.windowWidth <= mainStore.breakpoints.mobile
);

// const isGalleryOpen = computed(() => isDesktop.value || isItVideo.value);

const svgElement = computed(() => {
  return svgContainer.value?.children;
});

const downloadLink = computed(() => {
  const data = findContentData("DownloadFile");

  return data?.data?.list?.[0]?.file?.[0]?.url
});

const projectTitle = computed(() => buildingStore?.selectedProject?.title);

const modalEvent = (e: boolean, index: number): void => {
  currentImage.value = index;
  activeModal.value = e;
  window.liveCameraUrl = buildingStore.selectedProject?.liveCamera;
};

const router = useRouter();

const findContentData = (compName: string): SecondaryDataInterface | undefined => {
  if (!selectedComponentsState.value?.length) return;
  const matchedComponentData = selectedComponentsState.value?.find(
    (comp) => comp?.conf?.componentName === compName
  );
  return matchedComponentData;
};

/** HANDLE MOUSE HOVER OVER FLOOR GROUPS  */
const handleFloorSelect = (event: Event): void => {
  let target: Element | null = null,
    targetParent: HTMLElement | null = null;
  if (event.target instanceof Element || event.target !== null)
    target = event.target as Element;
  if (!target) {
    showTooltip.value = false;
    return;
  }
  if (target.tagName !== "path") {
    showTooltip.value = false;
    return;
  }
  targetParent = target.parentElement;
  if (!targetParent) {
    showTooltip.value = false;
    return;
  }
  hoveredFloor.value = {
    floor_number: targetParent.getAttribute("floor_number"),
    sold_flats: targetParent.getAttribute("sold_flats"),
    reserved_flats: targetParent.getAttribute("reserved_flats"),
    available_flats: targetParent.getAttribute("available_flats"),
    floor_status: targetParent.getAttribute("floor_status"),
  };

  hoveredBlock.value = {
    block_number: targetParent.getAttribute("block_number"),
  };

  showTooltip.value = true;
};

const handleFloorClick = (event: Event) => {
  const eventTarget = event.currentTarget as EventTarget & HTMLElement;
  const floorRoute = eventTarget?.getAttribute("route");
  const availableFlats = eventTarget?.getAttribute("available_flats");
  const reservedFlats = eventTarget?.getAttribute("reserved_flats");
  if (!floorRoute) console.warn("Floor route not found");
  if (
    (!eventTarget?.getAttribute("floor_status") ||
      eventTarget?.getAttribute("floor_status") === "available" ||
      eventTarget?.getAttribute("floor_status") === "reserved") &&
    (Number(availableFlats) || Number(reservedFlats))
  ) {
    sendEvent("floor-visit", { blockType: eventTarget?.getAttribute("floor_block") || "only-block", floorNumber: eventTarget?.getAttribute("floor_number"), projectType: buildingStore.selectedProject?.slug });

    router.push(floorRoute as string);
  }
};

const handleBlockClick = (event: Event) => {
  const eventTarget = event.currentTarget as EventTarget & HTMLElement;
  const blockRoute = eventTarget?.getAttribute("route");
  if (!blockRoute) console.warn("Block route not found");

  router.push(blockRoute as string);
};

const changeFloor = (direction: string) => {
  const floorPolygons = document.querySelectorAll(".projects-render__svg g");
  const floorNumbers: any = [];

  floorPolygons.forEach((floor) =>
    floorNumbers.push(floor.getAttribute("floor_number"))
  );
  let selectFloorNumber: any;

  if (hoveredBlock.value?.block_number) {
    const index = buildingStore.selectedProjectBlocks?.findIndex(x => x.slug == hoveredBlock.value?.block_number) || 0;
    const nextIndex = direction === "bottom"
      ? (index - 1 < 0 ? (buildingStore.selectedProjectBlocks?.length || 1) - 1 : index - 1)
      : (index + 1 > (buildingStore.selectedProjectBlocks?.length || 1) - 1 ? 0 : index + 1);

    selectFloorNumber = buildingStore.selectedProjectBlocks?.[nextIndex]?.title;
  } else {
    selectFloorNumber = Number(hoveredFloor.value?.floor_number);

    if (buildingStore.selectedProjectFloors?.length) {
      if (direction === "bottom") {
        for (let i = Number(buildingStore.selectedProjectFloors?.length) - 1; i >= 0; i--) {
          if (buildingStore.selectedProjectFloors[i]?.conf != "sold" && buildingStore.selectedProjectFloors[i]?.development_flats_status?.available && buildingStore.selectedProjectFloors[i]?.floor_number < selectFloorNumber) {
            selectFloorNumber = buildingStore.selectedProjectFloors[i].floor_number;

            break;
          }
        }
      } else {
        for (let i = 0; i < buildingStore.selectedProjectFloors?.length; i++) {
          if (buildingStore.selectedProjectFloors[i]?.conf != "sold" && buildingStore.selectedProjectFloors[i]?.development_flats_status?.available && buildingStore.selectedProjectFloors[i]?.floor_number > selectFloorNumber) {
            selectFloorNumber = buildingStore.selectedProjectFloors[i].floor_number;

            break;
          }
        }
      }
    }

    // selectFloorNumber = direction === "bottom"
    //   ? parseInt(hoveredFloor.value?.floor_number as string) - 1
    //   : parseInt(hoveredFloor.value?.floor_number as string) + 1;

    // if (
    //   !floorNumbers.some((num: any) => num == selectFloorNumber) &&
    //   !(selectFloorNumber > floorNumberMax.value) &&
    //   !(selectFloorNumber < floorNumberMin.value)
    // ) {
    //   for (let i = 0; i < Number(buildingStore.selectedProjectFloors?.length); i++) {
    //     selectFloorNumber =
    //       direction === "bottom" ? selectFloorNumber - 1 : selectFloorNumber + 1;
    //     if (floorNumbers.some((num: any) => num == selectFloorNumber)) {
    //       break;
    //     }
    //   }
    // }
  
    if (parseInt(selectFloorNumber) < floorNumberMin.value) {
      selectFloorNumber = floorNumberMin.value;
    }
  
    if (parseInt(selectFloorNumber) > floorNumberMax.value) {
      selectFloorNumber = floorNumberMax.value;
    }
  }

  const paths = document.querySelectorAll(".projects-render__svg path");

  if (paths.length) {
    paths.forEach((path) => {
      path.classList.remove("active");
      const targetParent = path.parentElement;
      if ((hoveredBlock.value?.block_number && targetParent?.getAttribute("block_number") == selectFloorNumber) || targetParent?.getAttribute("floor_number") == selectFloorNumber) {
        path.classList.add("active");
        hoveredFloor.value = {
          floor_number: targetParent?.getAttribute("floor_number") || null,
          sold_flats: targetParent?.getAttribute("sold_flats") || null,
          reserved_flats: targetParent?.getAttribute("reserved_flats") || null,
          available_flats: targetParent?.getAttribute("available_flats") || null,
          floor_status: targetParent?.getAttribute("floor_status") || null,
        };

        hoveredBlock.value = {
          block_number: targetParent?.getAttribute("block_number") || undefined,
        };
      }
    });
  }
};

const goToFloor = () => {
  const groups = document.querySelectorAll(".projects-render__svg g");

  let floorEl = null;
  groups.forEach((g) => {
    if (hoveredBlock.value?.block_number) {
      if (g.getAttribute("block_number") === hoveredBlock.value?.block_number) {
        floorEl = g;
      }
    } else {
      if (g.getAttribute("floor_number") === hoveredFloor.value?.floor_number) {
        floorEl = g;
      }
    }
  });

  if (hoveredBlock.value?.block_number && floorEl) {
    router.push(floorEl.getAttribute("route"));
  } else if (
    floorEl?.getAttribute("route") &&
    (Number(floorEl?.getAttribute("available_flats")) ||
      Number(floorEl?.getAttribute("reserved_flats"))) &&
    (!floorEl?.getAttribute("floor_status") ||
      floorEl?.getAttribute("floor_status") === "available" ||
      floorEl?.getAttribute("floor_status") === "reserved")
  ) {
    sendEvent("floor-visit", { blockType: floorEl?.getAttribute("floor_block") || "only-block", floorNumber: floorEl?.getAttribute("floor_number"), projectType: buildingStore.selectedProject?.slug });

    router.push(floorEl.getAttribute("route"));
  }
};

/** SET POLYGON VALUES: BLOCK/NO-BLOCK */
const setFloorValues = (newRender: HTMLElement): void => {
  groupEls.value = newRender?.children ? Array.from(newRender.children) : [];
  let defaultFloor: any = {};

  const blockMap = new Map(buildingStore.selectedProjectBlocks?.map(block => [block?.id, `block-${block?.slug}`]));

  groupEls.value?.forEach((floor) => {
    floor?.addEventListener("click", handleFloorClick);
    const floorKey = floor?.getAttribute("id");
    if (!buildingStore.selectedProject?.polygonDataToArray?.length)
      console.warn("No polygon data found on this project!");
    if (!floorKey) console.warn("Floor key not found!");

    const selectedFloorPolygonData = !mainStore.isBlockFlow
      ? buildingStore.selectedProject?.polygonDataToArray.find(
          (polygonData) => polygonData?.key === floorKey
        )
      : buildingStore.selectedBlock?.polygonDataToArray.find(
          (polygonData) => polygonData?.key === floorKey
      );
    if (!selectedFloorPolygonData?.id)
      console.warn(
        `Polygon does not have ID: ${selectedFloorPolygonData?.key}`
      );

    const selectedFloor = buildingStore.selectedProjectFloors?.find(
      (floor) => Number(floor?.id) === Number(selectedFloorPolygonData?.id)
    );

    if (!selectedFloor?.route)
      console.warn("Selected floor does not have a route!");
    if (!selectedFloor) return;

    if (selectedFloor?.floor_number < floorNumberMin.value && selectedFloor?.conf != "sold" && selectedFloor?.development_flats_status?.available) {
      floorNumberMin.value = selectedFloor.floor_number;
      defaultFloor = selectedFloor;
    }

    if (selectedFloor?.floor_number > floorNumberMax.value) {
      floorNumberMax.value = selectedFloor.floor_number;
    }

    floor.setAttribute("route", selectedFloor.route);
    floor.setAttribute(
      "floor_number",
      (selectedFloor?.floor_number || "")?.toString()
    );
    floor.setAttribute(
      "sold_flats",
      (selectedFloor?.development_flats_status?.sold || "0")?.toString()
    );
    floor.setAttribute(
      "reserved_flats",
      (selectedFloor?.development_flats_status?.reserved || "0")?.toString()
    );
    floor.setAttribute(
      "available_flats",
      (selectedFloor?.development_flats_status?.available || "0")?.toString()
    );
    floor.setAttribute(
      "floor_status",
      selectedFloor.conf?.length ? (selectedFloor.conf as string) : ""
    );

    const blockVal = blockMap?.get(selectedFloor?.block_id);

    if (blockVal) {
      floor.setAttribute(
        "floor_block",
        blockVal
      );
    }
  });

  if (!defaultFloorSet.value) {
    hoveredFloor.value = {
      floor_number: (defaultFloor?.floor_number || "")?.toString(),
      sold_flats: (
        defaultFloor?.development_flats_status?.sold || ""
      )?.toString(),
      reserved_flats: (
        defaultFloor?.development_flats_status?.reserved || ""
      )?.toString(),
      available_flats: (
        defaultFloor?.development_flats_status?.available || ""
      )?.toString(),
      floor_status: "",
    };
    defaultFloorSet.value = true;
  }

  if (!isDesktop.value && hoveredFloor.value?.floor_number) {
    const paths = document.querySelectorAll(".projects-render__svg path");

    if (paths.length) {
      paths.forEach((path) => {
        path.classList.remove("active");
        if (
          path.parentNode?.getAttribute("floor_number") ===
          hoveredFloor.value?.floor_number
        ) {
          path.classList.add("active");
        }
      });
    }
  }
};

/** SET POLYGON VALUES: BLOCKS */
const setBlockValues = (newRender: HTMLElement): void => {
  groupEls.value = Array.from(newRender?.children);
  const defaultblock: BlockHover = {
    block_number: '',
  };
  groupEls.value?.forEach((block) => {
    block?.addEventListener("click", handleBlockClick);
    const blockKey = block?.getAttribute("id");
    if (!buildingStore.selectedProject?.polygonDataToArray?.length)
      console.warn("No polygon data found on this project!");
    if (!blockKey) console.warn("block key not found!");
    const selectedBlockPolygonData =
      buildingStore.selectedProject?.polygonDataToArray.find(
        (polygonData) => polygonData?.key === blockKey
      );
    if (!selectedBlockPolygonData?.id)
      console.warn(
        `Polygon does not have ID: ${selectedBlockPolygonData?.key}`
      );
    const selectedBlock = buildingStore.selectedProjectBlocks?.find(
      (block) => Number(block?.id) === Number(selectedBlockPolygonData?.id)
    );

    block.setAttribute("route", selectedBlock?.route as string);
    block.setAttribute(
      "block_number",
      (selectedBlock?.slug || "")?.toString()
    );

    if (!defaultblock?.block_number) {
      defaultblock.block_number = selectedBlock?.slug;
    }
  });

  hoveredBlock.value = {
    block_number: (defaultblock?.block_number || "")?.toString(),
  };

  // if (!isDesktop.value && hoveredBlock.value?.block_number) {
  //   const paths = document.querySelectorAll(".projects-render__svg path");

  //   if (paths.length) {
  //     paths.forEach((path) => {
  //       path.classList.remove("active");
  //       if (
  //         path.parentNode.getAttribute("block_number") ===
  //         hoveredBlock.value.block_number
  //       ) {
  //         path.classList.add("active");
  //       }
  //     });
  //   }
  // }
};

watch(
  [svgElement, projectTitle],
  async (ns, os) => {
    if (!ns[0] || !ns[1]) return;

    setTimeout(() => {
      if (
        (!buildingStore.selectedProjectBlocks?.length && !mainStore.isBlockFlow) ||
        (buildingStore.selectedProjectBlocks?.length && mainStore.isBlockFlow) ||
        buildingStore.selectedProject?.conf?.includes("exclude_block_view")
      ) {
        setFloorValues(ns[0]?.[0] as HTMLElement);
      } else {
        setBlockValues(ns[0]?.[0] as HTMLElement);
      }
    }, 1000);
  },
  {
    immediate: true,
    deep: true,
  }
);

watch(
  () => mainStore?.lang,
  () => {
    mainStore.setTaxonomy("project_status");
  },
  {
    immediate: true,
  },
);

onBeforeUnmount(() => {
  groupEls.value?.forEach((group) => {
    group.removeEventListener(
      "click",
      !buildingStore.selectedProjectBlocks?.length
        ? handleFloorClick
        : handleBlockClick
    );
  }); 
});
</script>

<template>
  <section
    class="projects-render pos-rel"
    ref="projectsRenderEl"
    :key="projectTitle"
  >
  <!-- <ClientOnly>
    <teleport to="#teleported">
      <Transition name="fade">
        <LazyGallery
          v-if="activeModal"
          @close="modalEvent(false, 0)"
          :isVideo="isItVideo"
          :videoLink="buildingStore.selectedProject?.liveCamera"
          :isOpen="isGalleryOpen"
          :imageStyle="true"
          :currentImages="currentImage"
          :data="buildingStore.selectedProjectRenderImage"
        />
      </Transition>
    </teleport>
  </ClientOnly> -->

    <!-- {{ !buildingStore?.selectedProjectBlocks }} -->
    <Tooltip
      v-if="
        (
          /<svg.*?>(.+)<\/svg>/.test(buildingStore?.selectedProjectRenderSvg || '') &&
          buildingStore?.selectedProjectRenderSvg &&
          buildingStore?.selectedProjectRenderImage
        ) || mainStore?.isBlockFlow
      "
      :class="{
        'projects-render__tooltip--visible':
          (buildingStore?.selectedProject?.polygonDataToArray && showTooltip) ||
          !isDesktop,
        'projects-render__tooltip--down-disabled':
          Number(hoveredFloor?.floor_number) == floorNumberMin,
        'projects-render__tooltip--up-disabled':
          Number(hoveredFloor?.floor_number) == floorNumberMax,
      }"
      :selectedFloor="hoveredFloor"
      :selectedBlock="hoveredBlock"
      @floorChange="changeFloor"
      @goToFloor="goToFloor"
    />

    <div class="projects-render__filter pos-abs">
      <ClientOnly>
        <Filters :onlyProjects="true" />
      </ClientOnly>
      
      <a
        v-if="downloadLink"
        :href="downloadLink"
        target="_blank"
        class="projects-render__presentation"
      >
        <Button btnText="View Presentation" />
      </a>
    </div>

    <div
      v-if="buildingStore.selectedProject?.liveCamera"
      class="projects-render__live pos-abs pointer"
      @click="modalEvent(true, 0)"
    >
      <LiveCameraButton />
    </div>

    <div
      class="projects-render__select pos-rel"
      @mousemove="handleFloorSelect($event)"
    >
      <Transition name="fade">
        <div
          class="projects-render__svg"
          ref="svgContainer"
          v-if="buildingStore?.selectedProjectRenderSvg"
          v-html="
            mainStore?.isBlockFlow
              ? buildingStore?.selectedBlock?.polygon_svg
              : buildingStore?.selectedProjectRenderSvg
          "
        ></div>
      </Transition>

      <img
        class="pos-abs"
        v-if="!mainStore?.isBlockFlow"
        :src="
          isMobile
            ? buildingStore?.selectedProjectRenderImageMobile
            : buildingStore?.selectedProjectRenderImage
        "
        :alt="buildingStore?.selectedProject?.title"
      />
      
      <img
        class="pos-abs"
        v-else
        :src="
          isMobile
            ? buildingStore?.selectedBlock?.polygonImage?.[0]?.devices?.mobile
            : buildingStore?.selectedBlock?.polygonImage?.[0]?.devices?.desktop
        "
        :alt="buildingStore?.selectedProject?.title"
      />
    </div>
  </section>
</template>

<style lang="scss">
	.projects-render {
		width: 100%;
    // min-height: 100vh;
		// min-height: 600px;
		@include maxq(mobile) {
			position: relative;
		}
		&__filter {
			z-index: 3;
			// left: 435px;
			left: 96px;
			top: 96px;
			@include maxq(desktop-2k) {
				// left: 290px;
				left: 64px;
				top: 64px;
			}
			@include maxq(desktop-lg) {
				// left: 218px;
				left: 48px;
				top: 48px;
			}
			@include maxq(tablet) {
				left: 32px;
				// top: 120px;
				top: 32px;
			}
			@include maxq(mobile) {
				left: 0;
				right: 0;
				top: 0;
			}

      .button {
        display: none;
      }

      .floor-filters {
        margin-top: 0;
        background-color: transparent;

        .swiper-wrapper {
          height: 100%;
        }

        &-type {
          justify-content: flex-start;
        }

        @include maxq(mobile) {
          width: 100%;
          padding: 0;
          border: none;
        }

        .swiper-slide {
          margin-right: 0 !important;

          @include maxq(mobile) {
            width: 100%;
          }
        }
      }

      .swiper,
      .custom-burger-dropdown,
      .burgerdropdown {
        @include maxq(mobile) {
          width: 100%;
        }
      }
  }

  &__presentation {
    position: absolute;
    left: 664px;
    top: 33px;

    @include maxq(desktop-2k) {
      left: 500px;
      top: 21px;
    }

    @include maxq(desktop-lg) {
      left: 342px;
      top: 17px;
    }

    @include maxq(desktop) {
      left: 326px;
    }

    @include maxq(tablet) {
      left: 294px;
    }

    @include maxq(mobile) {
      top: 60px;
      left: 0;
    }
    
    .button {
      width: max-content;
      display: block !important;
      height: 106px;

      @include maxq(desktop-2k) {
        height: 86px;
      }

      @include maxq(desktop-lg) {
        height: 60px;
      }
    }
  }

  &__live {
    z-index: 3;
    right: 96px;
    top: 96px;
    @include maxq(desktop-2k) {
      right: 64px;
      top: 64px;
    }
    @include maxq(desktop-lg) {
      right: 48px;
      top: 48px;
    }
    @include maxq(tablet) {
      right: 32px;
      top: 32px;
    }
    @include maxq(mobile) {
      display: none;
    }
  }

  &__select {
    svg {
      position: relative;
      z-index: 2;
    }

    circle {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }

    path {
      cursor: pointer;
      opacity: 1;
      fill: rgba($apHover, 0.2);
      stroke: $apHover;
      stroke-width: 0.5px;

      @include easeOut(400, all);
      @include hover {
        fill: rgba($apHover, 0.6);
      }
      &.active {
        fill: rgba($apHover, 0.6);
      }
    }

    g {
      &[available_flats="0"] {
        path {
          @include hover {
            stroke: $sold;
            fill: rgba($sold, 0.6);
          }
          &.active {
            fill: rgba($sold, 0.6);
          }
        }
        &:not([sold_flats="0"]) {
          path {
            @include hover {
              stroke: $sold;
              fill: rgba($sold, 0.6);
            }
            &.active {
              fill: rgba($sold, 0.6);
            }
          }
        }
        &:not([reserved_flats="0"]) {
          path {
            @include hover {
              stroke: $reservedApHover;
              fill: rgba($reservedApHover, 0.6);
            }
            &.active {
              fill: rgba($reservedApHover, 0.6);
            }
          }
        }
      }

      &[floor_status="sold"] {
        path {
          @include hover {
            stroke: $sold;
            fill: rgba($sold, 0.6);
          }
          &.active {
            fill: rgba($sold, 0.6);
          }
        }
      }
      &[floor_status="reserved"] {
        path {
          @include hover {
            stroke: $reservedApHover;
            fill: rgba($reservedApHover, 0.6);
          }
          &.active {
            fill: rgba($reservedApHover, 0.6);
          }
        }
      }
    }

    img {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include maxq(mobile) {
      padding-top: calc(100% / (464 / 722));
    }
  }

  &__svg {
    @include maxq(mobile) {
      display: none;
    }
  }

  &__tooltip {
    pointer-events: none;
    &--down-disabled {
      .projects-render__tooltip-arrow:last-child {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    &--visible {
      pointer-events: all;
    }

    &--up-disabled {
      .projects-render__tooltip-arrow:first-child {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    @include maxq(mobile) {
      pointer-events: all;
    }
  }
}
</style>
