<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 20.25V3.75"
      stroke="#231F20"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.25 10.5L12 3.75L18.75 10.5"
      stroke="#231F20"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
