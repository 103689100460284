<script setup lang="ts">
/**
 * @author Irakli Kandelaki, Luka Tchigladze
 */

/** IMPORT HOOKS */
import { onMounted, ref, nextTick } from "vue";

/** IMPORT TRANSLATE FUNCTION */

/** IMPORT STORE */
import { useMainStore } from "@/store/main";
import { useBuildingStore } from "@/store/building";

/** IMPORT COMPONENTS */
import Button from "@/components/regularComponents/UIComponents/Button.vue";
import ArrowIcon from "./ArrowIcon.vue";

/** IMPORT TYPES */
import type { Ref } from "vue";
import type { FloorHover, BlockHover } from "../ts/renderTypes";

/** EMITS */
const emit = defineEmits(["floorChange", "goToFloor"]);

/** PROPS */
const props = defineProps<{
  selectedFloor?: FloorHover;
  selectedBlock?: BlockHover;
}>();

const buildingStore = useBuildingStore();

/** REACTIVE DATA */
const tooltipTemplate: Ref<HTMLElement | undefined> = ref();
const bottomVal: Ref<number> = ref(100);
const renderWrapper: Ref<HTMLElement | undefined | null> = ref();

/** USE MAIN STORE */
const mainStore = useMainStore();

const BOTTOM_OFFSET_VALUE = computed(() => {
  return mainStore?.windowWidth <= 1247
    ? 32 + 89
    : 120;
});

const setTooltipPosition = () => {
  const renderDimensions = renderWrapper.value?.getBoundingClientRect();

  if (!renderDimensions) {
    bottomVal.value = 100;
    return
  };

  const offset = BOTTOM_OFFSET_VALUE.value + (props.selectedBlock?.block_number ? 89 : 0);

  if (renderDimensions?.bottom > mainStore.windowHeight) {
    bottomVal.value = renderDimensions?.bottom - mainStore.windowHeight + offset
  } else {
    bottomVal.value = offset
  }
};

const handleChangeTop = (floorNum: number) => {
  buildingStore.selectedFloor = buildingStore.selectedProjectFloors?.find(
    (floorItem) => floorItem?.floor_number === Number(floorNum + 1)
  );
  emit("floorChange", "top");
};

const handleChangeBottom = (floorNum: number) => {
  buildingStore.selectedFloor = buildingStore.selectedProjectFloors?.find(
    (floorItem) => floorItem?.floor_number === Number(floorNum - 1)
  );
  emit("floorChange", "bottom");
};

onMounted(() => {
  renderWrapper.value = document?.querySelector<HTMLElement>(".projects-render");

  setTimeout(setTooltipPosition, 1000);

  window.addEventListener("scroll", setTooltipPosition);
});

onUnmounted(() => {
  window.removeEventListener("scroll", setTooltipPosition);
});
</script>

<template>
  <div
    class="projects-render__tooltip pos-abs bg-white d-flex al-center"
    ref="tooltipTemplate"
    :style="{ bottom: `${bottomVal}px` }"
    :class="{ 'projects-render__tooltip--block': selectedBlock?.block_number }"
  >
    <div class="projects-render__tooltip-inner w-full d-flex">
      <template
        v-if="
          mainStore.windowWidth > mainStore.breakpoints.tablet &&
          selectedBlock?.block_number
        "
      >
        <div class="block-tooltip">
          <h3 class="text-center no-flicker">{{ selectedBlock?.block_number }}</h3>
          <span class="d-block w-full p-14 uppercase text-center no-flicker">
            {{ $t("block") }}
          </span>
        </div>
      </template>

      <template v-else>
        <div class="projects-render__tooltip-left d-flex dir-col j-center">
          <div class="projects-render__tooltip-left-in d-flex">
            <div class="projects-render__tooltip-left-col d-flex dir-col j-center">
              <div class="projects-render__tooltip-left-col--text">
                <h3 class="no-flicker">
                  {{ selectedBlock?.block_number || selectedFloor?.floor_number }}
                </h3>

                <span class="d-block w-full p-14 uppercase no-flicker">
                  {{ selectedBlock?.block_number ? $t("block") : $t("floor") }}
                </span>
              </div>
            </div>

            <div
              class="projects-render__tooltip-right-col d-flex dir-col"
              v-if="mainStore.windowWidth <= mainStore.breakpoints.tablet"
            >
              <div
                @click="handleChangeTop(Number(selectedFloor?.floor_number))"
                class="projects-render__tooltip-arrow d-flex j-center al-center pointer"
              >
                <ArrowIcon />
              </div>
              <div
                @click="handleChangeBottom(Number(selectedFloor?.floor_number))"
                class="projects-render__tooltip-arrow d-flex j-center al-center pointer"
              >
                <ArrowIcon />
              </div>
            </div>
          </div>

          <Button
            v-if="mainStore.windowWidth <= mainStore.breakpoints.tablet"
            @click="emit('goToFloor')"
            :class="{
              disabled:
                !selectedBlock?.block_number &&
                (selectedFloor?.floor_status === 'sold' ||
                  (!Number(selectedFloor?.available_flats) &&
                    !Number(selectedFloor?.reserved_flats))),
            }"
            :isDisabled="
              !selectedBlock?.block_number &&
              (selectedFloor?.floor_status === 'sold' ||
                (!Number(selectedFloor?.available_flats) &&
                  !Number(selectedFloor?.reserved_flats)))
            "
            :btn-type="9"
            btnText="go"
          />
        </div>

        <div
          class="projects-render__tooltip-right-col d-flex dir-col j-btw"
          :class="{
            'projects-render__tooltip-right-col--single':
              [
                selectedFloor?.available_flats,
                selectedFloor?.sold_flats,
                selectedFloor?.reserved_flats,
              ]?.filter((x) => x === '0')?.length > 1 || selectedFloor?.floor_status,
          }"
          v-if="
            mainStore.windowWidth > mainStore.breakpoints.mobile_sm &&
            !selectedBlock?.block_number
          "
        >
          <div
            v-if="selectedFloor?.floor_status === 'sold'"
            class="d-flex h-full j-center al-center sold-text uppercase"
          >
            {{ $t("sold") }}
          </div>

          <div
            v-else-if="selectedFloor?.floor_status === 'reserved'"
            class="d-flex h-full j-center al-center reserved-text uppercase"
          >
            {{ $t("reserved") }}
          </div>

          <template
            v-else-if="
              Number(selectedFloor?.available_flats) ||
              (!Number(selectedFloor?.available_flats) &&
                Number(selectedFloor?.sold_flats) &&
                Number(selectedFloor?.reserved_flats))
            "
          >
            <div class="d-flex al-center" v-if="Number(selectedFloor?.sold_flats)">
              <h3 class="no-flicker">{{ selectedFloor?.sold_flats }}</h3>
              <span class="p-14 uppercase no-flicker">{{ $t("sold") }}</span>
            </div>

            <div class="d-flex al-center" v-if="Number(selectedFloor?.reserved_flats)">
              <h3 class="no-flicker">{{ selectedFloor?.reserved_flats }}</h3>
              <span class="p-14 uppercase no-flicker">{{ $t("reserved") }}</span>
            </div>

            <div class="d-flex al-center" v-if="Number(selectedFloor?.available_flats)">
              <h3 class="no-flicker">{{ selectedFloor?.available_flats }}</h3>
              <span class="p-14 uppercase no-flicker">{{ $t("available") }}</span>
            </div>
          </template>

          <template v-else>
            <div
              v-if="
                [selectedFloor?.sold_flats, selectedFloor?.reserved_flats].every(
                  (x) => x === null
                )
              "
              class="d-flex h-full j-center al-center no-flats-text uppercase"
            >
              {{ $t("no flats") }}
            </div>

            <div
              v-else-if="
                Number(selectedFloor?.sold_flats) &&
                !Number(selectedFloor?.reserved_flats)
              "
              class="d-flex h-full j-center al-center sold-text uppercase"
            >
              {{ $t("sold") }}
            </div>

            <div
              v-else-if="Number(selectedFloor?.reserved_flats)"
              class="d-flex h-full j-center al-center reserved-text uppercase"
            >
              {{ $t("reserved") }}
            </div>

            <div v-else class="d-flex h-full j-center al-center sold-text uppercase">
              {{ $t("sold") }}
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
.projects-render__tooltip {
  left: 144px;
  padding: 46px;
  opacity: 0;
  // transform: translateY(-50%);
  z-index: 3;
  @include easeOut(400, opacity);
  @include maxq(desktop-2k) {
    padding: 22px;
    left: 112px;
  }
  @include maxq(desktop-lg) {
    padding: 16px;
    left: 96px;
  }

  @include maxq(desktop) {
    left: 80px;
  }
  @include maxq(tablet) {
    left: 32px;
    flex-direction: column;
    .button {
      height: 46px;
      width: 100%;
      margin-top: 16px;
      &.disabled {
        span {
          color: $black;
        }
      }
    }
  }
  @include maxq(mobile) {
    top: unset !important;
    bottom: 32px !important;
  }
  @include maxq(mobile-sm) {
    right: 32px;
    bottom: -27px;
    .button {
      width: 22%;
      margin-top: 0;
      height: 100%;
    }
  }
  @include maxq(mobile-xs) {
    right: 5px;
    left: 5px;
    bottom: -41px;
    padding: 10px;
  }
  &--visible {
    opacity: 1;
  }
  h3 {
    font-size: 96px;
    line-height: 100px;
    @include weight(300);
    @include maxq(desktop-2k) {
      font-size: 64px;
      line-height: 70px;
    }
    @include maxq(desktop-lg) {
      font-size: 48px;
      line-height: 55px;
    }
    @include maxq(tablet) {
      font-size: 32px;
      line-height: 26px;
    }
  }
  &-left {
    @include maxq(tablet) {
      margin-right: 16px;
    }

    @include maxq(mobile-sm) {
      width: 100%;
      height: 80px;
      margin-right: 0;
      flex-direction: row !important;
    }

    .projects-render__tooltip-right-col {
      @include maxq(tablet) {
        background-color: $white !important;
        width: fit-content;
        padding: 0;
        border: 1px solid #e1e1e1;
        border-left: none;

        .projects-render__tooltip-arrow {
          width: 40px;
          height: 47px;
          &:nth-child(2) {
            transform: rotate(180deg);
          }
        }
      }

      @include maxq(mobile-sm) {
        .projects-render__tooltip-arrow {
          width: 100%;
          height: 100%;

          &:first-child {
            border-right: 1px solid #e1e1e1;
          }
        }
      }
    }

    &-in {
      @include maxq(mobile-sm) {
        width: 78%;
      }
    }
  }
  &-left-col {
    min-width: 60px;
    margin-right: 64px;
    span {
      font-size: 24px;
      line-height: 18px;
      margin-top: 4px;

      @include maxq(desktop-lg) {
        font-size: 12px;
      }
    }
    span,
    h3 {
      text-align: center;

      @include maxq(mobile-sm) {
        padding-left: 2px;
        text-align: left;
      }
    }
    @include maxq(desktop-2k) {
      margin-right: 32px;
    }
    @include maxq(desktop-lg) {
      margin-right: 24px;
    }
    @include maxq(tablet) {
      margin-right: 0px;
      padding: 18px;
      border: 1px solid #e1e1e1;
    }
    @include maxq(mobile-sm) {
      width: 44.4%;
      padding: 18px 8px;
    }
    &--text {
      @include maxq(mobile-sm) {
        width: max-content;
        text-align: center;

        h3 {
          width: max-content;
          margin: 0 auto;
        }
      }
    }
  }
  &-right-col {
    background-color: $disabled;
    padding: 32px;
    min-height: 150px;
    h3 {
      min-width: 110px;
      margin-right: 16px;
      text-align: center;
    }
    @include maxq(desktop-2k) {
      h3 {
        min-width: 61px;
        margin-right: 10px;
      }
      // width: 262px;
    }
    @include maxq(desktop-lg) {
      padding: 24px;
      h3 {
        min-width: 46px;
        margin-right: 8px;
      }
      // width: 210px;
    }
    @include maxq(tablet) {
      min-height: auto;
      justify-content: space-around;
    }
    @include maxq(mobile-sm) {
      flex-direction: row !important;
      width: 55.6% !important;
    }

    .sold-text {
      font-size: 30px;
      font-weight: 300;
    }

    .reserved-text {
      font-size: 30px;
      font-weight: 300;
    }

    .no-flats-text {
      font-size: 30px;
      font-weight: 300;
    }

    &--single {
      min-height: auto;
      justify-content: center;
    }
  }
  &--block {
    @include maxq(desktop-lg) {
      padding: 28px 24px;
    }

    h3 {
      margin-bottom: 4px;

      @include maxq(desktop-lg) {
        font-size: 48px;
        line-height: 38px;
      }
    }

    span {
      @include maxq(desktop-lg) {
        font-size: 12px;
        line-height: 10px;
      }
    }

    .projects-render__tooltip-left {
      margin-right: 0;
    }
  }
}

html[lang="ka"] .reserved-text {
  font-size: 22px;
}

html[lang="ru"] .reserved-text {
  font-size: 20px;
}
</style>
